@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-ms-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

@mixin animation($animation) {
  -webkit-animation: #{$animation};
	-moz-animation: #{$animation};
	-ms-animation: #{$animation};
	animation: #{$animation};
}

$spinner-height: 7px;
$green: #8bc34a;
.spinner {
  position: relative;
  width: 100%;
  height: $spinner-height;
  opacity: 1;
  background-color: lighten($green, 25%);
  overflow: hidden;
  margin-top: 0;
  .bar {
    height: $spinner-height;
    background-color: $green;
    position: absolute;
  }
  .bar1 {
    left: 50%;
    width: 10%;
    @include animation(bar1 7s infinite linear);
  }
  .bar2 {
    left: 0%;
    width: 0%;
    @include animation(bar2 7s infinite);
  }
}

@include keyframes(bar1) {
	0% { width: 0%; left: 0%; }
  10% { width: 30%; left: 100%; }
  19.99% { width: 30%; left: 100%; }
  20% { width: 0%; left: 0%; }
  30% { width: 80%; left: 100%; }
  30.01% { width: 0%; left: 0%; }
  40% { width: 5%; left: 30%; }
  50% { width: 50%; left: 100%; }
  50.01% { width: 0%; left: 0%; }
  60% { width: 60%; left: 20%; }
  70% { width: 5%; left: 100%; }
  70.01% { width: 0%; left: 0%; }
  80% { width: 50%; left: 30%; }
  90% { width: 10%; left: 80%; }
  100% { width: 20%; left: 100%; }
}

@include keyframes(bar2) {
	0% { width: 0%; left: 0%; }
  10% { width: 60%; }
  19.99% { width: 40%; left: 100%; }
  20% { width: 0%; left: 0%; }
  25% { width: 10%; left: 10%; }
  30% { width: 40%; left: 30%; }
  40% { width: 60%; left: 100%; }
  40.01% { width: 0%; left: 0%; }
  50% { width: 10%; left: 40%; }
  60% { width: 30%; left: 100%; }
  60.01% { width: 0%; left: 0%; }
  70% { width: 10%; left: 40%; }
  80% { width: 5%; left: 100%; }
  80.01% { width: 0%; left: 0%; }
  90% { width: 70%; left: 10%; }
  100% { width: 10%; left: 100%; }
}