.selected {
    background-color: #E6E6E0;
  }
  
  

#Menu:hover {  
    cursor: pointer;
    background-color: #E6E6E0;
}
