.menuWrapper {
    position: absolute;
    height: 2.5rem;
    width : auto;
    z-index: +999;
  }
  
  .menu {
    width: auto;
    height: 100%;
    background: #FBFBFA;
    box-shadow: rgba(15, 15, 15, 0.05) 0px 0px 0px 1px, rgba(15, 15, 15, 0.1) 0px 3px 6px, rgba(15, 15, 15, 0.2) 0px 9px 24px;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    z-index: +999;
  }
  
  .menuItem {
    width: auto;
    min-width: 4rem;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: .25rem .75rem;
    font-size: 0.875rem;
    font-family: 'Fira Sans';
    font-weight: 700;
    border-right: 1px solid rgb(243, 243, 243);
    img {
        width: 100%;
        height: 100%;
        max-width: 1rem;
        max-height: 1.125rem;
    }
  }
  
  .menuItem:last-of-type {
      border-right: none;
  }
  
  .menuItem:hover {
      background: #E6E6E0;
      cursor: pointer;
  }
  
  .menuItem:first-of-type:hover {
      border-top-left-radius: .5rem;
      border-bottom-left-radius: .5rem;
  }
  
  .menuItem:last-of-type:hover {
      border-top-right-radius: .5rem;
      border-bottom-right-radius: .5rem;
  }