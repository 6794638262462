
.block {
  padding: 0.25rem;
  // Better support for safari
  -webkit-user-select: text;
  user-select: text;
}

.block:focus,
.isDragging,
.blockSelected {
  background: #E6E6E0;
  outline-color: #E6E6E0;
  & ~ .dragHandle {
    opacity: 1;
  }
}

.placeholder {
  color: rgba(72, 72, 72, 0.25);
}

.draggable .block {
  display: inline-block;
  width: calc(98% - 1rem);
}

.draggable:hover {
  .block {
    background: #fafafa;
    outline-color: #E6E6E0;
  }
  .dragHandle {
    opacity: 1;
  }
}

.dragHandle {
  opacity: 0;
  display: inline-block;
  width: 1rem;
  img {
    display: block;
    margin: auto;
  }
}

.image {
  display: inline-block;
  width: calc(100% - 1rem);
  padding: 0.25rem;
  img {
    display: block;
    max-height: 600px;
    max-width: 100%;
    margin: 0 auto;
  }
}

.blockSelected.image {
  opacity: 0.75;
}

.fileInputLabel {
  display: block;
  padding: 0.5rem 0.25rem;
  color: #888888;
  font-size: 0.875rem;
  letter-spacing: 0.5px;
}
